import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SiteMeta from '../components/meta-data'
import Nav from '../components/navigation'
import Contact from '../components/contact'
import Footer from '../components/footer'

class WechatAdvertisingPage extends React.Component{

    render(){
        const metaData = get(this.props, 'data.site.siteMetadata.wechatAdvertisingPage')
        const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
        return(
            <div>
                <SiteMeta metaData={metaData}/>
                <HeroSection servicesNav={servicesNav}/>
                <MainSection/>
                <Contact/>
                <Footer/>
            </div>
        )
    }

}

class HeroSection extends React.Component {
    render() {
        const title = "wechat advertising solution"
        const subtitle = "reach chinese tourists in UK & Europe"
        const heroImage = {
            background:`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("/img/wechat/banner-background.jpg")`,
            backgroundSize:'cover'    
        }
        const {servicesNav} = this.props
        return(
            <section className="hero is-fullheight" style={heroImage}>
                <div className="hero-head">
                <Nav servicesNav={servicesNav}/>
                </div>
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <figure className="imgae is-128x128">
                                    <img src="/img/wechat/head-banner.svg" alt={title}/>    
                                </figure>    
                            </div>
                            <div className="column">
                                <div>
                                    <h1 className="title has-text-white is-capitalized is-size-1 is-size-3-mobile">{title}</h1>
                                    <h1 className="title has-text-white is-capitalized is-size-1 is-size-3-mobile">{subtitle}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </section>
        )
    }
}

class MainSection extends React.Component {
    render() {
        const benefits = [
            {
                title:"Sale leads generation",
                image:"/img/wechat/sale-leads-generation.svg"
            },
            {
                title:"M-commerce promotion",
                image:"/img/wechat/m-commerce-promotion.svg"
            },
            {
                title:"Online to Offline retail store promotion",
                image:"/img/wechat/online-to-offline-retail-store-promotion.svg"
            },
            {
                title:"Promote WeChat official account",
                image:"/img/wechat/promote-wechat-official-account.svg"
            },
            {
                title:"Promote WeChat mini program",
                image:"/img/wechat/promote-wechat-mini-program.svg"
            },
            {
                title:"Promote seasonal promotion with coupon",
                image:"/img/wechat/promote-seasonal-promotion-with-coupon.svg"
            }
        ]
        const soulutions = [
            {
                title:"Localized ads copywriting & design",
                image:"/img/wechat/localized-ads-copywriting-design.svg"
            },
            {
                title:"Advertising media planning",
                image:"/img/wechat/advertising-media-planning.svg"
            },
            {
                title:"Advertising analytics & campaign measurement",
                image:"/img/wechat/advertising-analytics-campaign-measurement.svg" 
            }

        ]
        return(
            <div className="wechat-main">
                <section className="section">
                    <div className="container">
                        <h1 className="title is-capitalized has-text-centered is-size-1  is-size-3-mobile has-text-weight-bold">
                            Opportunity that brands can’t miss
                        </h1>
                        <hr className="has-background-danger divider"></hr>
                        <div className="columns">
                            <div className="column">
                                <figure className="image is-128X128">
                                    <img src="/img/wechat/opportunity-that-brands-cannot-miss.jpg" alt="Opportunity that brands can’t miss"/>
                                </figure>
                            </div>
                            <div className="column">
                                <h1 className="title">Europe has become the favoriate long haul travel destination among Chinese tourists. 
                                Chinese tourism in Europe keep growing in 2019 and beyond. 
                                Engage with Red Digital China with a team of Chinese tourism 
                                sales consultant and Chinese digital marketing experts to help you reach Chinese group and individaul travel tourists with our WeChat advertising solution.</h1>    
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <h1 className="title is-capitalized has-text-centered is-size-1  is-size-3-mobile has-text-weight-bold">
                            WeChat Eco-System   
                        </h1>
                        <hr className="has-background-danger divider"></hr>
                        <div className="columns is-centered">
                            <div className="column is-9">
                                <figure className="image">
                                    <img src="/img/wechat/wechat-eco-system.png" alt="wechat eco system"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section benfits">
                    <div className="container">
                        <h1 className="title is-capitalized has-text-centered is-size-1  is-size-3-mobile has-text-weight-bold">
                            WeChat advertising benefits      
                        </h1>
                        <hr className="has-background-danger divider"></hr>
                        <div className="columns is-multiline">
                            {
                                benefits.map((benfit, index)=>{
                                    return(
                                        <div className="column is-one-third has-text-centered" key={index}>
                                            <h3 className="title">{benfit.title}</h3>
                                            <figure className="image">
                                                <img src={benfit.image} alt={benfit.title}/>
                                            </figure>
                                        </div>
                                    )
                                })   
                            } 
                        </div>
                    </div>
                </section>
                <section className="section solutions">
                    <div className="container">
                        <h1 className="title is-capitalized has-text-centered is-size-1  is-size-3-mobile has-text-weight-bold">
                            WeChat advertising solution      
                        </h1>
                        <hr className="has-background-danger divider"></hr>
                        <div className="columns is-multiline">
                            {
                                soulutions.map((solution, index)=>{
                                    return(
                                        <div className="column is-one-third has-text-centered" key={index}>
                                            <h3 className="title">{solution.title}</h3>
                                            <figure className="image">
                                                <img src={solution.image} alt={solution.title}/>
                                            </figure>
                                        </div>
                                    )
                                })   
                            } 
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="contariner">
                        <div className="columns is-centered">
                            <div className="column is-9">
                                <h1 className="title has-text-centered">
                                    Want to learn more our completed WeChat advertising targeting features and solution? Contact us to learn more!         
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )    
    }
}

export default WechatAdvertisingPage

export const pageQuery = graphql`
    query WechatAdvertisingPage {
        site {
            siteMetadata {
              wechatAdvertisingPage {
                title
                description
                imageUrl
                siteUrl
              }
            }
        } 
        allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
            edges {
              node {
                title
                slug
              }
            }
        }  
    }
`